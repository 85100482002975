/* ==== Namespace to hold all variables and methods. ==== */
var app = {};
/* ==== Initializing all Code within one INIT function. ==== */
app.init = function () {
  //All functions etc needed for Site.
  // $(".animsition").animsition({
  //   inClass: 'fade-in',
  //   outClass: 'fade-out',
  //   inDuration: 0,
  //   outDuration: 0,
  //   linkElement: '.animsition-link',
  //   // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
  //   loading: true,
  //   loadingParentElement: 'body', //animsition wrapper element
  //   loadingClass: 'animsition-loading',
  //   loadingInner: '', // e.g '<img src="loading.svg" />'
  //   timeout: false,
  //   timeoutCountdown: 5000,
  //   onLoadEvent: true,
  //   browser: [ 'animation-duration', '-webkit-animation-duration'],
  //   // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
  //   // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
  //   overlay : false,
  //   overlayClass : 'animsition-overlay-slide',
  //   overlayParentElement : 'body',
  //   transition: function(url){ window.location.href = url; }
  // });
  $(".collapse.show").each(function () {
    $(this).prev(".card-header").find(".fa").addClass("fa-minus").removeClass("fa-plus");
  });
  // Toggle plus minus icon on show hide of collapse element
  $(".collapse").on('show.bs.collapse', function () {
    $(this).prev(".card-header").find(".fa").removeClass("fa-plus").addClass("fa-minus");
  }).on('hide.bs.collapse', function () {
    $(this).prev(".card-header").find(".fa").removeClass("fa-minus").addClass("fa-plus");
  });
};
/* ==== Initializing the JS for the Website Inside the Document Ready - Calling the INIT Function ==== */
$(document).ready(function () {
  // console.log('JS Ready');
  $("#contactForm input,#contactForm textarea").jqBootstrapValidation();
  function normalizeSlideHeights() {
    $('.carousel').each(function () {
      var items = $('.carousel-item', this);
      // reset the height
      items.css('min-height', 0);
      // set the height
      var maxHeight = Math.max.apply(null,
        items.map(function () {
          return $(this).outerHeight()
        }).get());
      items.css('min-height', maxHeight + 'px');
    })
  }
  $(window).on(
    'load resize orientationchange',
    normalizeSlideHeights);
  app.init();
  $('body').removeClass('fade-out');
});